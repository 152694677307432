import { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import loginApi from '../services/authApi';
import authTwoFA from '../services/auth2FA';

const useAuth = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isInTwoFactorAuth, setIsInTwoFactorAuth] = useState(false);
  const [errorMsgApi, setErrorMsgApi] = useState(null);

  const navigate = useNavigate();

  /* We need to add more functions here as remember password, etc... */
  const login = async (username) => {
    try {
      const data = await loginApi(username);
      setIsInTwoFactorAuth(true);
      return data;
    } catch (error) {
      setErrorMsgApi('Invalid email or unexpected error occurred. Please try again.');
      return error;
    }
  };

  const login2FA = async (email, otp) => {
    try {
      const data = await authTwoFA(email, otp);
      if (data.error) {
        return data.error;
      }
      localStorage.setItem('token', data.auth_token);
      localStorage.setItem('email', email);
      setIsLoggedIn(true);
      navigate('/dashboards/home');
    } catch (error) {
      console.error('Two FA failed', error);
    }
    return null;
  };

  const logout = () => {
    localStorage.clear();
    setIsLoggedIn(false);
    navigate('/auth/signin');
  };

  const isAuthenticated = () => {
    return !!localStorage.getItem('token');
  };

  return useMemo(
    () => ({
      isLoggedIn,
      isInTwoFactorAuth,
      login,
      login2FA,
      isAuthenticated: isAuthenticated(),
      logout,
      setIsInTwoFactorAuth,
      errorMsgApi,
    }),
    [
      isLoggedIn,
      isInTwoFactorAuth,
      login,
      login2FA,
      isAuthenticated,
      logout,
      setIsInTwoFactorAuth,
      errorMsgApi,
    ],
  );
};

export default useAuth;
