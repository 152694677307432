import rootParams from './rootParams';

const getMitigationsDetails = async (id) => {
  const myHeaders = new Headers();
  const token = localStorage.getItem('token');
  myHeaders.append('Authorization', `bearer ${token}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const response = await fetch(
    `${rootParams.rootUrl}admin/get-single-mitigation-details?id=${id}`,
    requestOptions,
  );

  if (!response.ok) {
    throw new Error('Get Mitigations Details failed');
  }

  return response.json();
};

export default getMitigationsDetails;
