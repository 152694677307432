import rootParams from './rootParams';

const updateMitigation = async (params) => {
  const myHeaders = new Headers();
  const token = localStorage.getItem('token');
  myHeaders.append('Authorization', `bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Accept', 'application/json');

  const filteredParams = Object.fromEntries(
    Object.entries(params).filter(([, value]) => value !== ''),
  );

  const raw = JSON.stringify(filteredParams);

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const baseUrl = `${rootParams.rootUrl}admin/update-mitigation`;

  const response = await fetch(baseUrl, requestOptions);

  if (!response.ok) {
    throw new Error('Get update mitigation failed');
  }

  return response.json();
};

export default updateMitigation;
